.App{
  display: flex;
  flex-direction: column;
  align-items: center;

  }

body{
  background-color: rgb(94 94 94);
}


#left div{
  position: relative;
}

/* p {
  bottom: -10px;
  right: 10px;
  position: absolute;
} */



/* #left{
  display: grid;
  align-items: center;
  text-shadow: 4px 4px 8px #000000;
  font-size:40px;
  color:white;
  margin-left: 20px;

}
#right{

  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 15% 15% 25% 45%;
} */

/* #one{
  background-color: #FFBD35;
}
#two{
  background-color: #1A8AAF;
}
#three{
  background-color: #D58668;
}
#four{
  background-color: #11383F;
} */










span {
  font-weight: normal;
}

#fishImg {
  height: 300px;
  border-radius: 18px;
}

#fishWrapper {
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width: 800px;
margin: 20px;
}

#mainDiv{
  display:flex;
  justify-content: right;
  align-items: right;
}

#picDiv{
text-align: center;
}

#infoDiv{
  display: flex;
  flex-direction: column;
  flex-flow: column;
  padding: 20px;
  width: 800px;
}

#infoDiv h3{
  margin-top: 4px;
  margin-bottom: 4px;
}

